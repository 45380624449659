@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden;
}

.section-header:after {
    background-color: var(--color, blue);
}

.shake {
  animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  -webkit-animation: shake 0.5s cubic-bezier(.36,.07,.19,.97) both;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
}

@keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
      -webkit-transform: translate3d(-1px, 0, 0);
      -moz-transform: translate3d(-1px, 0, 0);
      -ms-transform: translate3d(-1px, 0, 0);
      -o-transform: translate3d(-1px, 0, 0);
}
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
      -webkit-transform: translate3d(2px, 0, 0);
      -moz-transform: translate3d(2px, 0, 0);
      -ms-transform: translate3d(2px, 0, 0);
      -o-transform: translate3d(2px, 0, 0);
}
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
      -webkit-transform: translate3d(-4px, 0, 0);
      -moz-transform: translate3d(-4px, 0, 0);
      -ms-transform: translate3d(-4px, 0, 0);
      -o-transform: translate3d(-4px, 0, 0);
}
  
    40%, 60% {
      transform: translate3d(4px, 0, 0);
      -webkit-transform: translate3d(4px, 0, 0);
      -moz-transform: translate3d(4px, 0, 0);
      -ms-transform: translate3d(4px, 0, 0);
      -o-transform: translate3d(4px, 0, 0);
}
  }
  .swiper-custom_initialized .swiper-wrapper {
    -webkit-transition-timing-function:linear!important; 
    -o-transition-timing-function:linear!important;
    transition-timing-function:linear!important; 
  }